(function($, window, document) {
    
    $(function() {
        //##Variables
        var $body = $('body'),
            $searchBtn = $('#search_btn span'),
            $searchForm = $('#search_btn form'),
            $hamburgerMob = $('.hamburger_nav'),
            $rightHeader = $('#primary_nav_wrap'),
            $window = $(window),
            $doc = $(document),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables

        //OFI
        objectFitImages();

        //SEARCH BTN 
        $searchBtn.on('click', function(e){
            $searchBtn.toggleClass('is--active');
            $searchForm.fadeToggle('500');
        });
        
        //Header Hamburger Mobile
        $hamburgerMob.on('click', function(e){
            $body.toggleClass('nav--toggled');
            $hamburgerMob.toggleClass('is--active');
            $rightHeader.fadeToggle('fast');
        });

        //BANNER SLIDER init 
        $("#home_banner_slider").owlCarousel({
            items:1,
            loop:true,
            autoplay: true,
            animation: "fade",
            autoplayTimeout: 3000
        });
        //VIDEO SLIDER init 
        $('#slider_video').flexslider({
            animation: "slide",
            controlNav: false,
            loop: true,
            animationLoop: false,
            drag: true,
            controlNav: "thumbnails",
            slideshow: false,
            sync: "#related_slider_video"
        });
        $('#related_slider_video').flexslider({
            animation: "slide",
            controlNav: false,
            animationLoop: false,
            slideshow: false,
            itemWidth: 170,
            loop: true,
            drag: true,
            itemMargin: 10,
            asNavFor: '#slider_video'
        });
        

        console.log("%c Developed by: Kutia Digital Agency http://kutia.net", "padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d");
    });
}(window.jQuery, window, document));
